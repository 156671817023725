.view {
  width: 100%;
  height: 100%;
  .container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .actions-container {
        display: flex;
        align-items: center;
        gap: 24px;
      }
    }

    .accordions-container {
      display: flex;
      flex-direction: column;
      gap: 24px;
      .MuiPaper-root {
        box-shadow: var(--box-shadow) !important;
        border-radius: 15px;
        position: static;
      }

      // .tabels {
      //   display: flex;
      //   padding: 24px;
      //   table {
      //     flex: 1;
      //     width: 50%;
      //     border-collapse: collapse;
      //     th,
      //     td {
      //       border: 1px solid #ddd;
      //       text-align: center;
      //       padding: 8px;
      //     }

      //     th {
      //       background-color: #4caf50;
      //       color: white;
      //     }

      //     tr:nth-child(even) {
      //       background-color: #f2f2f2;
      //     }
      //   }
      // }

      // .chats {
      //   display: flex;
      //   flex-direction: column;
      //   gap: 24px;
      //   .chat {
      //     display: flex;
      //     flex-direction: column;
      //     justify-content: space-between;
      //     box-shadow: var(--box-shadow);
      //     border-radius: 10px;
      //     padding: 16px;
      //     .chat-header {
      //       display: flex;
      //       align-items: center;
      //       justify-content: space-between;
      //       .text-container {
      //         display: flex;
      //         align-items: center;
      //         gap: 24px;
      //         img {
      //           width: 50px;
      //           height: 50px;
      //           border-radius: 50%;
      //         }
      //         .wrapper {
      //           display: flex;
      //           flex-direction: column;
      //           span {
      //             font-size: 14px;
      //             font-weight: 400;
      //             line-height: 22.5px;
      //             text-align: right;
      //             &:last-child {
      //               color: #a4acad;
      //             }
      //           }
      //         }
      //       }
      //       .actions-container {
      //         display: flex;
      //         align-items: center;
      //         gap: 16px;
      //         div {
      //           cursor: pointer;
      //           display: flex;
      //           align-items: center;
      //         }
      //       }
      //     }
      //     .chat-body {
      //     }
      //   }
      // }

      // .work-areas {
      //   display: flex;
      //   flex-direction: column;
      //   gap: 24px;
      //   .area {
      //     display: flex;
      //     align-items: flex-start;
      //     justify-content: space-between;
      //     box-shadow: var(--box-shadow);
      //     border-radius: 10px;
      //     padding: 16px;
      //     .text-container {
      //       display: flex;
      //       flex-direction: column;
      //       gap: 8px;
      //       span {
      //         font-size: 17px;
      //         font-weight: 700;
      //         line-height: 25.5px;

      //         &:first-child {
      //           color: #ff7300;
      //         }
      //         &:last-child {
      //           color: #a4acad;
      //         }
      //       }
      //     }
      //     .actions-container {
      //       display: flex;
      //       align-items: center;
      //       gap: 8px;
      //       div {
      //         display: flex;
      //         align-items: center;
      //         cursor: pointer;
      //       }
      //     }
      //   }
      // }

      // .training-details {
      //   display: flex;
      //   flex-direction: column;
      //   align-items: center;
      //   gap: 24px;
      //   width: 100%;
      //   .header {
      //     width: 100%;
      //     display: flex;
      //     align-items: center;
      //     justify-content: space-between;
      //     .actions-container {
      //       display: flex;
      //       align-items: center;
      //       justify-content: flex-end;
      //     }
      //   }
      //   .data-grid {
      //     width: 100%;
      //     display: flex;
      //     align-items: center;
      //     .MuiDataGrid-cell {
      //       display: flex;
      //       align-items: center;
      //     }
      //     .cell-action {
      //       display: flex !important;
      //       align-items: center;
      //       justify-content: center;
      //       gap: 16px;
      //       .cell-btn {
      //         display: flex;
      //         align-items: center;
      //         gap: 10px;
      //         cursor: pointer;
      //         .action-btn {
      //           outline: none;
      //           border: none;
      //           padding: 2px 10px;
      //           font-size: 16px;
      //           font-weight: 600;
      //           color: white;
      //           border-radius: 5px;
      //           background: var(--btn-background);
      //           cursor: pointer;
      //           display: flex;
      //           align-items: center;
      //           justify-content: center;
      //           gap: 10px;
      //           &:nth-of-type(2) {
      //             background: white;
      //             color: var(--main-color);
      //             border: 1px solid var(--main-color);
      //           }
      //         }
      //       }
      //     }
      //   }
      // }

      // .close-student {
      //   display: flex;
      //   flex-direction: column;
      //   gap: 24px;
      //   .actions-container {
      //     width: 100%;
      //     display: flex;
      //     justify-content: flex-end;
      //   }
      //   .student {
      //     display: flex;
      //     flex-direction: column;
      //     justify-content: space-between;
      //     box-shadow: var(--box-shadow);
      //     border-radius: 10px;
      //     padding: 16px;
      //     gap: 8px;
      //     .student-header {
      //       display: flex;
      //       align-items: center;
      //       justify-content: space-between;
      //       .text-container {
      //         display: flex;
      //         align-items: center;
      //         gap: 24px;
      //         .wrapper {
      //           display: flex;
      //           flex-direction: column;
      //           span {
      //             font-size: 14px;
      //             font-weight: 400;
      //             line-height: 22.5px;
      //             text-align: right;
      //             &:last-child {
      //               color: #a4acad;
      //             }
      //           }
      //         }
      //       }
      //       .actions-container {
      //         display: flex;
      //         align-items: center;
      //         justify-content: flex-end;
      //         div {
      //           cursor: pointer;
      //           display: flex;
      //           align-items: center;
      //         }
      //       }
      //     }
      //     .student-body {
      //       display: flex;
      //       gap: 8px;
      //       align-items: center;
      //       justify-content: flex-start;
      //       span {
      //         padding: 2px 4px;
      //         border-radius: 8px;
      //         background-color: #cefffc;
      //         color: #1bbcb3;
      //         font-size: 10px;
      //         font-weight: 400;
      //         line-height: 15px;
      //         &:nth-child(2) {
      //           background-color: #ffe1e8;
      //           color: #ff416d;
      //         }
      //       }
      //     }
      //   }
      // }

      // .rates {
      //   display: flex;
      //   flex-direction: column;
      //   gap: 24px;
      //   .rate {
      //     display: flex;
      //     flex-direction: column;
      //     justify-content: space-between;
      //     box-shadow: var(--box-shadow);
      //     border-radius: 10px;
      //     padding: 16px;
      //     .rate-header {
      //       display: flex;
      //       align-items: center;
      //       justify-content: space-between;
      //       .text-container {
      //         display: flex;
      //         align-items: center;
      //         gap: 24px;
      //         .wrapper {
      //           display: flex;
      //           flex-direction: column;
      //           span {
      //             font-size: 14px;
      //             font-weight: 400;
      //             line-height: 22.5px;
      //             text-align: right;
      //             &:last-child {
      //               color: #a4acad;
      //             }
      //           }
      //         }
      //       }

      //       .actions-container {
      //         display: flex;
      //         align-items: center;
      //         gap: 16px;
      //         div {
      //           cursor: pointer;
      //           display: flex;
      //           align-items: center;
      //         }
      //         input[type="checkbox"] {
      //           -webkit-appearance: none;
      //           -moz-appearance: none;
      //           appearance: none;
      //           width: 30px;
      //           height: 30px;
      //           border: 2px solid #ccc;
      //           border-radius: 4px;
      //           outline: none;
      //           cursor: pointer;
      //           transition: background-color 0.3s ease;
      //           &:checked {
      //             background: var(--btn-background);
      //             &::after {
      //               content: "\2714";
      //               font-size: 16px;
      //               color: #fff;
      //               display: flex;
      //               justify-content: center;
      //               align-items: center;
      //             }
      //           }
      //         }
      //       }
      //     }
      //     .rate-body {
      //     }
      //   }
      // }
    }
  }
}

@media (max-width: 767px) {
  .view {
    width: 100%;
    height: 100%;
    .container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 24px;
      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .actions-container {
          display: flex;
          align-items: center;
          gap: 24px;
        }
      }

      .accordions-container {
        display: flex;
        flex-direction: column;
        gap: 24px;
        .MuiPaper-root {
          box-shadow: var(--box-shadow) !important;
          border-radius: 15px;
          position: static;
        }

        .tabels {
          padding: 8px;
          table {
            flex: 1;
            width: 100%;
            border-collapse: collapse;
            th,
            td {
              border: 1px solid #ddd;
              text-align: center;
              padding: 8px;
            }

            th {
              background-color: #4caf50;
              color: white;
            }

            tr:nth-child(even) {
              background-color: #f2f2f2;
            }
          }
        }

        .chats {
          display: flex;
          flex-direction: column;
          gap: 8px;
          .chat {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            box-shadow: var(--box-shadow);
            border-radius: 10px;
            padding: 16px;
            .chat-header {
              display: flex;
              align-items: center;
              justify-content: space-between;
              flex-direction: column;
              .text-container {
                display: flex;
                align-items: center;
                gap: 24px;
                .wrapper {
                  display: flex;
                  flex-direction: column;
                  span {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 22.5px;
                    text-align: right;
                    &:last-child {
                      color: #a4acad;
                    }
                  }
                }
              }
              .actions-container {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 16px;
                div {
                  cursor: pointer;
                  display: flex;
                  align-items: center;
                }
              }
            }
            .chat-body {
            }
          }
        }

        .work-areas {
          display: flex;
          flex-direction: column;
          gap: 24px;
          .area {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            box-shadow: var(--box-shadow);
            border-radius: 10px;
            padding: 16px;
            .text-container {
              display: flex;
              flex-direction: column;
              gap: 8px;
              span {
                font-size: 12px;
                font-weight: 700;
                line-height: 25.5px;

                &:first-child {
                  color: #ff7300;
                }
                &:last-child {
                  color: #a4acad;
                }
              }
            }
            .actions-container {
              display: flex;
              align-items: center;
              gap: 8px;
              div {
                display: flex;
                align-items: center;
                cursor: pointer;
              }
            }
          }
        }

        .training-details {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 24px;
          width: 100%;
          .header {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .actions-container {
              display: flex;
              align-items: center;
              justify-content: flex-end;
            }
          }
          .data-grid {
            width: 100%;
            display: flex;
            align-items: center;
            .MuiDataGrid-cell {
              display: flex;
              align-items: center;
            }
            .cell-action {
              display: flex !important;
              align-items: center;
              justify-content: center;
              gap: 16px;
              .cell-btn {
                display: flex;
                align-items: center;
                gap: 10px;
                cursor: pointer;
                .action-btn {
                  outline: none;
                  border: none;
                  padding: 2px 10px;
                  font-size: 16px;
                  font-weight: 600;
                  color: white;
                  border-radius: 5px;
                  background: var(--btn-background);
                  cursor: pointer;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 10px;
                  &:nth-of-type(2) {
                    background: white;
                    color: var(--main-color);
                    border: 1px solid var(--main-color);
                  }
                }
              }
            }
          }
        }

        .photos-videos {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          gap: 24px;
          .actions-container {
            width: 100%;
            display: flex;
            gap: 16px;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          }
          .photos-videos-container {
            display: flex;
            gap: 8px;
            flex-wrap: wrap;
            justify-content: center;
            .photo-container {
              position: relative;
              display: flex;
              justify-content: center;
              align-items: center;
              .photo {
                max-width: 225px;
                max-height: 230;
                object-fit: cover;
              }
              .icon {
                position: absolute;
                cursor: pointer;
                width: 50px;
                height: 50px;
              }
            }

            .video-container {
              position: relative;
              display: flex;
              justify-content: center;
              align-items: center;
              .video {
                max-width: 225px;
                max-height: 230;
                object-fit: cover;
              }
              .icon {
                position: absolute;
                top: 15px;
                left: 20px;
                cursor: pointer;
                width: 30px;
                height: 30px;
              }
            }
          }
        }

        .close-student {
          display: flex;
          flex-direction: column;
          gap: 24px;
          .actions-container {
            width: 100%;
            display: flex;
            justify-content: flex-end;
          }
          .student {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            box-shadow: var(--box-shadow);
            border-radius: 10px;
            padding: 16px;
            gap: 24px;
            .student-header {
              display: flex;
              align-items: flex-start;
              justify-content: space-between;
              flex-direction: column;
              gap: 16px;
              .text-container {
                display: flex;
                align-items: center;
                gap: 8px;
                .wrapper {
                  display: flex;
                  flex-direction: column;
                  span {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 22.5px;
                    text-align: right;
                    &:last-child {
                      color: #a4acad;
                    }
                  }
                }
              }
              .actions-container {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                div {
                  cursor: pointer;
                  display: flex;
                  align-items: center;
                  width: 100%;
                }
              }
            }
            .student-body {
              display: flex;
              gap: 8px;
              align-items: center;
              justify-content: flex-start;
              flex-direction: column;
              span {
                padding: 2px 4px;
                border-radius: 8px;
                background-color: #cefffc;
                color: #1bbcb3;
                font-size: 10px;
                font-weight: 400;
                line-height: 15px;
                &:nth-child(2) {
                  background-color: #ffe1e8;
                  color: #ff416d;
                }
              }
            }
          }
        }

        .rates {
          display: flex;
          flex-direction: column;
          gap: 24px;
          .rate {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            box-shadow: var(--box-shadow);
            border-radius: 10px;
            padding: 16px;
            .rate-header {
              display: flex;
              align-items: center;
              justify-content: space-between;
              flex-direction: column;
              .text-container {
                display: flex;
                align-items: center;
                gap: 24px;
                .wrapper {
                  display: flex;
                  flex-direction: column;
                  span {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 22.5px;
                    text-align: right;
                    &:last-child {
                      color: #a4acad;
                    }
                  }
                }
              }

              .actions-container {
                display: flex;
                align-items: center;
                gap: 16px;
                div {
                  cursor: pointer;
                  display: flex;
                  align-items: center;
                }
                input[type="checkbox"] {
                  -webkit-appearance: none;
                  -moz-appearance: none;
                  appearance: none;
                  width: 20px;
                  height: 20px;
                  border: 2px solid #ccc;
                  border-radius: 4px;
                  outline: none;
                  cursor: pointer;
                  transition: background-color 0.3s ease;
                  &:checked {
                    background: var(--btn-background);
                    &::after {
                      content: "\2714";
                      font-size: 10px;
                      color: #fff;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    }
                  }
                }
              }
            }
            .rate-body {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .view {
    width: 100%;
    height: 100%;
    .container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 24px;
      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .actions-container {
          display: flex;
          align-items: center;
          gap: 24px;
        }
      }

      .accordions-container {
        display: flex;
        flex-direction: column;
        gap: 24px;
        .MuiPaper-root {
          box-shadow: var(--box-shadow) !important;
          border-radius: 15px;
          position: static;
        }

        .tabels {
          display: flex;
          padding: 24px;
          table {
            flex: 1;
            width: 50%;
            border-collapse: collapse;
            th,
            td {
              border: 1px solid #ddd;
              text-align: center;
              padding: 8px;
              font-size: 12px;
            }

            th {
              background-color: #4caf50;
              color: white;
            }

            tr:nth-child(even) {
              background-color: #f2f2f2;
            }
          }
        }
      }
    }
  }
}
