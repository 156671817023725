.tabels {
  display: flex;
  padding: 24px;
  table {
    flex: 1;
    width: 50%;
    border-collapse: collapse;
    th,
    td {
      border: 1px solid #ddd;
      text-align: center;
      padding: 8px;
    }

    th {
      background-color: #4caf50;
      color: white;
    }

    tr:nth-child(even) {
      background-color: #f2f2f2;
    }
  }
}
