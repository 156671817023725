.social-media-links {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    form {
      gap: 16px;
      display: flex;
      flex-direction: column;
      .control-group {
        display: flex;
        align-items: center;
        gap: 16px;
        flex-wrap: wrap;
        label {
          display: flex;
          align-items: center;
          gap: 10px;
          margin-bottom: 8px;
        }
      }

      .action-control {
        align-self: flex-end;
      }
    }
  }
}

@media (max-width: 767px) {
  .social-media-links {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .container {
      display: flex;
      flex-direction: column;
      gap: 16px;
      form {
        width: 100%;
        .control-group {
          width: 100%;
          justify-content: center;
          flex-direction: column;
          width: 100%;
          .select {
            width: 100%;
          }
          .form-input {
            width: 100% !important;
          }
        }

        .action-control {
          align-self: flex-end;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
}
