.close-student {
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-height: 50vh;

  .actions-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .student {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: var(--box-shadow);
    border-radius: 10px;
    padding: 16px;
    gap: 8px;
    .student-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .text-container {
        display: flex;
        align-items: center;
        gap: 24px;
        width: 100%;
        .student-img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
        }
        .wrapper {
          display: flex;
          flex-direction: column;
          span {
            font-size: 14px;
            font-weight: 400;
            line-height: 22.5px;
            text-align: right;
            &:last-child {
              color: #a4acad;
            }
          }
        }
      }
      .actions-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        div {
          cursor: pointer;
          display: flex;
          align-items: center;
        }
      }
    }
    .student-body {
      display: flex;
      gap: 8px;
      align-items: center;
      justify-content: flex-start;
      span {
        padding: 2px 4px;
        border-radius: 8px;
        background-color: #cefffc;
        color: #1bbcb3;
        font-size: 10px;
        font-weight: 400;
        line-height: 15px;
        &:nth-child(2) {
          background-color: #ffe1e8;
          color: #ff416d;
        }
      }
    }
  }
}

.search-modal {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .container {
    width: 550px;
    background-color: white;
    border-radius: 20px;
    background: #fff;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    gap: 24px;

    .header {
      width: 100%;
      // padding-bottom: 16px;
      display: flex;
      align-items: center;
      gap: 16px;
      // border-bottom: 0.5px solid #eee;
    }

    .body {
      min-height: 300px;
    }
    .footer {
      width: 100%;
      display: flex;
      justify-content: center;
      gap: 16px;
      .action-btn {
        display: flex;
        width: 142px;
        height: 54px;
        padding: 10px;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: 800;
        gap: 10px;
        border-radius: 10px;
        border: none;
        outline: none;
        background: linear-gradient(91deg, #ff7300 0.18%, #ffcd4d 99.68%);
        cursor: pointer;
        &:nth-child(2) {
          background: white;
          color: var(--main-color);
          font-weight: 600;
          border-radius: 10px;
          outline: none;
          border: 2px solid var(--main-color);
        }
      }
    }
  }
}

// div.base-Popper-root {
//   margin-top: 40px !important;
// }
