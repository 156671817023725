.trainers {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    .spcial-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      width: 100%;
      .search {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;

        input {
          padding: 10px !important;
        }

        .filter-container {
          cursor: pointer;
          width: 45.6px !important;
          height: 45.6px !important;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .actions-container {
        display: flex;
        align-items: center;
        gap: 16px;
        .action-btn {
          outline: none;
          border: none;
          padding: 10px;
          font-weight: 600;
          color: white;
          border-radius: 5px;
          background: var(--btn-background);
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          &:nth-of-type(2) {
            background: white !important;
            color: var(--main-color);
            border: 1px solid var(--main-color);
          }
        }
      }
    }

    .data-grid {
      width: 100%;
      display: flex;
      align-items: center;
      .MuiDataGrid-cell {
        display: flex;
        align-items: center;
      }
      .cell-action {
        display: flex !important;
        align-items: center;
        justify-content: center;
        gap: 16px;
        .cell-btn {
          display: flex;
          align-items: center;
          gap: 10px;
          cursor: pointer;
          .action-btn {
            outline: none;
            border: none;
            padding: 2px 10px;
            font-size: 16px;
            font-weight: 600;
            color: white;
            border-radius: 5px;
            background: var(--btn-background);
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            &:nth-of-type(2) {
              background: white;
              color: var(--main-color);
              border: 1px solid var(--main-color);
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .trainers {
    width: 100%;
    .container {
      .spcial-header {
        flex-direction: column;
        .search {
          width: 100%;
          gap: 8px !important;
          .search-container {
            width: 100%;
            .icon {
              position: absolute;
              right: 10px;
              top: 50%;
              transform: translateY(-50%);
              font-size: 32px;
              color: rgba(96, 96, 96, 0.25);
            }

            input {
              width: 100%;
              height: 45.6px;
              flex-shrink: 0;
              font-size: 8px;
              border-radius: 50px;
              border: 1px solid rgba(24, 24, 24, 0.1);
              box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.25);
              outline: none;
              padding-right: 52px;
            }
          }

          .filter-container {
            cursor: pointer;
            width: 45.6px !important;
            height: 45.6px !important;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }

        .actions-container {
          display: flex;
          align-items: center;
          gap: 16px;
          .action-btn {
            outline: none;
            border: none;
            padding: 10px;
            font-weight: 600;
            color: white;
            border-radius: 5px;
            background: var(--btn-background);
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            &:nth-of-type(2) {
              background: white !important;
              color: var(--main-color);
              border: 1px solid var(--main-color);
            }
          }
        }
      }
      .data-grid {
        width: 100%;
        display: flex;
        align-items: center;
        .MuiDataGrid-cell {
          display: flex;
          align-items: center;
        }
        .cell-action {
          display: flex !important;
          align-items: center;
          justify-content: center;
          gap: 16px;
          .cell-btn {
            display: flex;
            align-items: center;
            gap: 10px;
            cursor: pointer;
            .action-btn {
              outline: none;
              border: none;
              padding: 2px 10px;
              font-size: 16px;
              font-weight: 600;
              color: white;
              border-radius: 5px;
              background: var(--btn-background);
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 10px;
              &:nth-of-type(2) {
                background: white;
                color: var(--main-color);
                border: 1px solid var(--main-color);
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .trainers {
    width: 100%;
    .container {
      .spcial-header {
        flex-direction: column;
        .search {
          width: 100%;
          gap: 8px !important;
          .search-container {
            width: 100%;

            .icon {
              position: absolute;
              right: 10px;
              top: 50%;
              transform: translateY(-50%);
              font-size: 32px;
              color: rgba(96, 96, 96, 0.25);
            }

            input {
              width: 100%;
              height: 45.6px;
              flex-shrink: 0;
              font-size: 8px;
              border-radius: 50px;
              border: 1px solid rgba(24, 24, 24, 0.1);
              box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.25);
              outline: none;
              padding-right: 52px;
            }
          }

          .filter-container {
            cursor: pointer;
            width: 45.6px !important;
            height: 45.6px !important;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }

        .actions-container {
          display: flex;
          align-items: center;
          gap: 16px;
          .action-btn {
            outline: none;
            border: none;
            padding: 10px;
            font-weight: 600;
            color: white;
            border-radius: 5px;
            background: var(--btn-background);
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            &:nth-of-type(2) {
              background: white !important;
              color: var(--main-color);
              border: 1px solid var(--main-color);
            }
          }
        }
      }

      .data-grid {
        width: 100%;
        display: flex;
        align-items: center;
        .MuiDataGrid-cell {
          display: flex;
          align-items: center;
        }
        .cell-action {
          display: flex !important;
          align-items: center;
          justify-content: center;
          gap: 16px;
          .cell-btn {
            display: flex;
            align-items: center;
            gap: 10px;
            cursor: pointer;
            .action-btn {
              outline: none;
              border: none;
              padding: 2px 10px;
              font-size: 16px;
              font-weight: 600;
              color: white;
              border-radius: 5px;
              background: var(--btn-background);
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 10px;
              &:nth-of-type(2) {
                background: white;
                color: var(--main-color);
                border: 1px solid var(--main-color);
              }
            }
          }
        }
      }
    }
  }
}
