.create-range {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    form {
      gap: 16px;
      display: flex;
      flex-direction: column;
      .file-group {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        input[type="file"] {
          display: none;
        }
        .file-label {
          display: flex;
          align-items: center;
          border-radius: 10px;
          height: 44px;
          gap: 16px;
          box-shadow: 0px 0px 0px 2px rgba(201, 201, 201, 0.47);
          padding: 0px 15px;
          cursor: pointer;
          .icon {
            font-weight: bolder;
            font-size: 16px;
          }
        }
        span {
          display: flex;
          align-items: center;
          gap: 10px;
          .icon {
            color: blue;
            cursor: pointer;

            width: 20px;
            height: 20px;
            &:hover {
              border-radius: 50%;
              border: 1px solid #777;
            }
          }
        }
        input {
          align-self: flex-start;
          width: 50%;
          height: 44px;
          box-shadow: var(--box-shadow);
          padding: 0px 16px;
          border: none;
          outline: none;
          background-color: #f5f5f540;
          border-radius: 10px;
        }
      }

      .control-group {
        display: flex;
        align-items: center;
        gap: 16px;
        flex-wrap: wrap;
        .form-input {
          width: 100% !important;
          input {
            width: 100% !important;
            height: auto;
            background-color: white;
            padding: 16.5px 14px;
            text-align: right;
          }
        }
        .form-label {
          &::after {
            content: "*";
            color: red;
          }
        }
      }

      .action-control {
        align-self: flex-end;
      }
    }
  }
}

@media (max-width: 767px) {
  .create-range {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .container {
      display: flex;
      flex-direction: column;
      gap: 16px;
      form {
        width: 100%;
        .control-group {
          width: 100%;
          justify-content: center;
          flex-direction: column;
          .select {
            width: 100%;
          }
          input {
            height: 40px !important;
            text-align: center;
            border-radius: 16px;
            border: none;
            outline: none;
            background-color: #f5f5f580;
            padding: 8px;
          }
          label:has(+ [required]) {
            &::after {
              content: "*";
              color: red;
            }
          }
          .form-input {
            width: 100% !important;
            input {
              width: 100% !important;
              height: auto !important;
              background-color: white;
              padding: 16.5px 14px;
            }
          }
        }

        .action-control {
          align-self: flex-end;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
}
