.navbar {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: white;
  max-height: 80px;
  z-index: 1;

  .navbar-container {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .right {
      flex: 1;
      display: flex;
      align-items: center;
      .logo-container {
        display: flex;
        width: 260px;
        justify-content: center;
      }
    }

    .center {
      flex: 2;
      width: 100%;
      display: flex;
      align-items: center;
      .MuiInputBase-root {
        padding: 4px !important;
      }
    }

    .left {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 20px;

      .notifications {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;

        .notifications-icon {
          position: relative;
          width: 40px;
          height: 40px;
          background-color: #ccc;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          .icon {
            color: #1093ee;
            font-size: 30px;
          }

          .notifications-numbers {
            position: absolute;
            right: -10px;
            top: -10px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 10px;
            width: 24px;
            height: 24px;
            border-radius: 4px;
            background-color: red;
            color: white;
          }
        }

        .notifications-panel {
          position: absolute;
          width: 360px;
          // left: -20px;
          left: 0;
          top: 50px;
          border-radius: 10px;
          height: auto;
          max-height: 500px;
          overflow-y: auto;
          box-shadow: var(--box-shadow);
          background-color: white;
          padding: 10px;
          display: flex;
          flex-direction: column;
          gap: 10px;
          scrollbar-width: thin;
          .notification {
            width: 100%;
            height: auto;
            border-radius: 10px;
            padding: 8px;
            display: flex;
            flex-direction: column;
            gap: 4px;
            box-shadow: var(--box-shadow);
            &:hover {
              background-color: #f8f8f8;
            }
            &.unread {
              background-color: #e6f1fc;
            }
            .notification-header {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .header-wrapper {
                display: flex;
                align-items: center;
                gap: 16px;
                p {
                  cursor: pointer;
                  font-weight: 400;
                  font-size: 12px;
                  &:hover {
                    color: #1093ee;
                  }
                }
              }
            }

            .notification-body {
              width: 100%;
              display: -webkit-box;
              min-height: 25px;
              line-clamp: 1;
              -webkit-line-clamp: 1; // Change this number to set the number of lines
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              font-size: 14px;
              cursor: pointer;
            }
            .notification-footer {
              width: 100%;
              display: flex;
              justify-content: flex-end;
              font-size: 12px;
              color: #989797;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .navbar {
    .navbar-container {
      .right {
        flex: 1;
        .page {
          display: none;
        }
        .logo-container {
          justify-content: flex-start;
          width: 60px;
          img {
            width: 60px;

            object-fit: cover;
          }
        }
        .icon-contianer {
          display: block;
          .icon {
            font-size: 30px;
            cursor: pointer;
            color: #777;
          }
        }
      }

      .center {
        flex: 2;
      }
      .left {
        flex: 1.5;
        gap: 8px;
        .search-container {
          display: none;
        }
        .notification-container {
          display: none;
        }
        .avatar-container {
          img {
            width: 40px;
            height: 40px;
          }
        }

        .notifications {
          display: flex;
          position: relative;
          align-items: center;
          justify-content: center;

          .notifications-icon {
            position: relative;
            width: 30px;
            height: 30px;
            background-color: #ccc;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            .icon {
              color: #1093ee;
              font-size: 24px;
            }

            .notifications-numbers {
              position: absolute;
              right: -10px;
              top: -10px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 10px;
              width: 24px;
              height: 24px;
              border-radius: 4px;
              background-color: red;
              color: white;
            }
          }

          .notifications-panel {
            left: -30px;

            .notification {
              width: 100%;
              height: auto;
              border-radius: 10px;
              padding: 8px;
              display: flex;
              flex-direction: column;
              gap: 4px;
              box-shadow: var(--box-shadow);
              &:hover {
                background-color: #f8f8f8;
              }
              &.unread {
                background-color: #e6f1fc;
              }
              .notification-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .header-wrapper {
                  display: flex;
                  align-items: center;
                  gap: 16px;
                  p {
                    cursor: pointer;
                    font-weight: 400;
                    font-size: 12px;
                    &:hover {
                      color: #1093ee;
                    }
                  }
                }
              }

              .notification-body {
                width: 100%;
                display: -webkit-box;
                min-height: 25px;
                line-clamp: 1;
                -webkit-line-clamp: 1; // Change this number to set the number of lines
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 14px;
                cursor: pointer;
              }
              .notification-footer {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                font-size: 12px;
                color: #989797;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .navbar {
    .container {
      .right {
        font-size: 14px;
        .logo-container {
          justify-content: flex-start;
          width: 160px;
        }
      }
    }
  }
}
