.create {
  height: 100%;
  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 24px;
    form {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      gap: 24px;
      .control-group {
        width: 100% !important;
        display: flex;
        align-items: center;
        .form-control {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 24px;
          flex: 1;
          input {
            align-self: flex-start;
            width: 100%;
            height: 44px;
            box-shadow: var(--box-shadow);
            padding: 0px 16px;
            border: none;
            outline: none;
            background-color: #f5f5f540;
            border-radius: 10px;
          }
          label {
            align-self: flex-start;
          }

          .select {
            width: 100%;
            height: 44px;
            box-shadow: var(--box-shadow);
            padding: 0px 16px;
            border: none;
            outline: none;
            background-color: #f5f5f540;
            border-radius: 10px;
          }
          .form-label {
            &::after {
              content: "*";
              color: red;
            }
          }
          textarea {
            width: 100%;
            height: 100%;
            max-width: 100%;
            max-height: 100%;
            padding: 8px;
            border-radius: 16px;
            background: rgba(245, 245, 245, 0.25);
            // border: none;
            outline: none;
            appearance: none;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .create {
    .container {
      form {
        justify-content: flex-start;
      }
    }
  }
  .modal {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .message {
      width: 90%;
      height: auto;
      top: 366px;
      left: 445px;
      gap: 0px;
      border-radius: 20px;
      opacity: 0px;
      background-color: white;
      padding: 24px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 24px;
      .header {
        display: flex;
        align-items: center;
        gap: 24px;
      }
      .body {
        display: flex;
        flex-direction: column;
        gap: 16px;
        .form-select {
          display: flex;
          align-items: center;
          gap: 16px;
          label {
            display: block;
            margin-bottom: 8px;
            color: #111;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            order: 1;
          }
          input[type="radio"] {
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            width: 20px;
            height: 20px;
            border: 2px solid #ccc;
            border-radius: 50%;
            outline: none;
            position: relative;
            &:checked {
              border-color: var(--main-color);
              &:before {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: var(--btn-background);
              }
            }
          }
        }
      }
      .footer {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .create {
    .container {
      form {
        justify-content: flex-start;
      }
    }
  }
}
