.question {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  .container {
    height: 100%;
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 24px;
    .spcial-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      width: 100%;
      .search {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;

        .search-container {
          display: flex;
          align-items: center;
          position: relative;
          .icon {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 32px;
            color: rgba(96, 96, 96, 0.25);
          }

          input {
            width: 367px;
            height: 45.6px;
            flex-shrink: 0;
            border-radius: 50px;
            border: 1px solid rgba(24, 24, 24, 0.1);
            box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.25);
            outline: none;
            padding-right: 52px;
          }
        }

        .filter-container {
          cursor: pointer;
          width: 45.6px !important;
          height: 45.6px !important;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .actions-container {
        display: flex;
        align-items: center;
        gap: 16px;
        // .action-btn {
        //   outline: none;
        //   border: none;
        //   padding: 10px;
        //   font-weight: 600;
        //   color: white;
        //   border-radius: 5px;
        //   background: var(--btn-background);
        //   cursor: pointer;
        //   display: flex;
        //   align-items: center;
        //   justify-content: center;
        //   gap: 10px;
        //   &:nth-of-type(2) {
        //     background: white !important;
        //     color: var(--main-color);
        //     border: 1px solid var(--main-color);
        //   }
        // }
      }
    }
    .data-grid {
      width: 100%;
      display: flex;
      align-items: center;
      .data-grid .MuiDataGrid-root {
        height: 100%;
      }
      .MuiDataGrid-cell {
        display: flex;
        align-items: center;
      }
      .cell-action {
        display: flex !important;
        align-items: center;
        justify-content: center;
        gap: 16px;
        .cell-btn {
          display: flex;
          align-items: center;
          gap: 10px;
          cursor: pointer;
          .action-btn {
            outline: none;
            border: none;
            padding: 2px 10px;
            font-size: 16px;
            font-weight: 600;
            color: white;
            border-radius: 5px;
            background: var(--btn-background);
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            &:nth-of-type(2) {
              background: white;
              color: var(--main-color);
              border: 1px solid var(--main-color);
            }
          }
        }
        .outlined {
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid var(--main-color);
          color: var(--main-color);
          background: white;
          height: 44px;
          font-size: 16px;
          font-weight: 600;
          border-radius: 5px;
          cursor: pointer;
          &:hover {
            background: var(--btn-background);
            color: white;
          }
        }
        .fill {
          outline: none;
          border: none;
          background: var(--btn-background);
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

.filter-modal-questions {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  .container {
    width: auto;
    background-color: white;
    border-radius: 20px;
    background: #fff;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 24px;
    outline: none;
    .header {
      width: 100%;
      padding-bottom: 16px;
      display: flex;
      align-items: center;
      gap: 16px;
      border-bottom: 0.5px solid #eee;
    }

    .body {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      flex-wrap: wrap;
      .item {
        width: fit-content;
        display: flex;
        align-items: center;
        gap: 16px;

        input[type="checkbox"] {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          width: 40px;
          height: 30px;
          border: 2px solid #ccc;
          border-radius: 4px;
          outline: none;
          cursor: pointer;
          transition: background-color 0.3s ease;
          &:checked {
            background: var(--btn-background);

            &::after {
              content: "\2714";
              font-size: 16px;
              color: #fff;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }

        select {
          width: 100%;
          height: 100%;
          color: var(--main-color);
          font-weight: 600;
          border-radius: 10px;
          outline: none;
          border: 2px solid var(--main-color);
          text-align: center;
          background-color: inherit;
          height: 44px;
          cursor: pointer;
          &.checked {
            color: white;
            font-weight: 600;
            border-radius: 10px;
            outline: none;
            border: none;
            text-align: center;
            background-color: inherit;
            height: 44px;
            cursor: pointer;
            background: linear-gradient(91deg, #ff7300 0.18%, #ffcd4d 99.68%);
            option {
              color: var(--main-color);
            }
          }
        }
      }
    }

    .footer {
      width: 100%;
      display: flex;
      justify-content: center;
      gap: 16px;
      .action-btn {
        display: flex;
        width: 141.556px;
        height: 54px;
        padding: 10px;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: 800;
        gap: 10px;
        border-radius: 10px;
        border: none;
        outline: none;
        background: linear-gradient(91deg, #ff7300 0.18%, #ffcd4d 99.68%);
        cursor: pointer;
        &:nth-child(2) {
          background: white;
          color: var(--main-color);
          font-weight: 600;
          border-radius: 10px;
          outline: none;
          border: 2px solid var(--main-color);
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .question {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .container {
      flex-direction: column;
      .spcial-header {
        flex-direction: column;
        .search {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 16px;
          .search-container {
            width: 100%;
            .icon {
              position: absolute;
              right: 10px;
              top: 50%;
              transform: translateY(-50%);
              font-size: 32px;
              color: rgba(96, 96, 96, 0.25);
            }

            input {
              width: 100%;
              height: 45.6px;
              flex-shrink: 0;
              border-radius: 50px;
              border: 1px solid rgba(24, 24, 24, 0.1);
              box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.25);
              outline: none;
              padding-right: 52px;
            }
          }

          .filter-container {
            width: 100%;
            cursor: pointer;
            width: 45.6px !important;
            height: 45.6px !important;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }

        .actions-container {
          flex-direction: column;
          width: 100%;
        }
      }
    }

    .data-grid {
      width: 100%;
      display: flex;
      align-items: center;
      .MuiDataGrid-cell {
        display: flex;
        align-items: center;
      }
      .cell-action {
        display: flex !important;
        align-items: center;
        justify-content: center;
        gap: 16px;
        .cell-btn {
          display: flex;
          align-items: center;
          gap: 10px;
          cursor: pointer;
          .action-btn {
            outline: none;
            border: none;
            padding: 2px 10px;
            font-size: 16px;
            font-weight: 600;
            color: white;
            border-radius: 5px;
            background: var(--btn-background);
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            &:nth-of-type(2) {
              background: white;
              color: var(--main-color);
              border: 1px solid var(--main-color);
            }
          }
        }
      }
    }
  }

  .filter-container {
    cursor: pointer;
    width: 45.6px !important;
    height: 45.6px !important;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .question {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .container {
      flex-direction: column;
      .spcial-header {
        width: 100%;
        flex-direction: column;
        .search {
          width: 100%;
          .search-container {
            width: 100%;
            .icon {
              position: absolute;
              right: 10px;
              top: 50%;
              transform: translateY(-50%);
              font-size: 32px;
              color: rgba(96, 96, 96, 0.25);
            }

            input {
              width: 100%;
              height: 45.6px;
              flex-shrink: 0;
              border-radius: 50px;
              border: 1px solid rgba(24, 24, 24, 0.1);
              box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.25);
              outline: none;
              padding-right: 52px;
            }
          }
          .filter-container {
            width: 100%;
            cursor: pointer;
            width: 45.6px !important;
            height: 45.6px !important;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        .actions-container {
          width: 100% !important;
          justify-content: space-between !important;
        }
      }
    }

    .data-grid {
      width: 100%;
      display: flex;
      align-items: center;
      .MuiDataGrid-cell {
        display: flex;
        align-items: center;
      }
      .cell-action {
        display: flex !important;
        align-items: center;
        justify-content: center;
        gap: 16px;
        .cell-btn {
          display: flex;
          align-items: center;
          gap: 10px;
          cursor: pointer;
          .action-btn {
            outline: none;
            border: none;
            padding: 2px 10px;
            font-size: 16px;
            font-weight: 600;
            color: white;
            border-radius: 5px;
            background: var(--btn-background);
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            &:nth-of-type(2) {
              background: white;
              color: var(--main-color);
              border: 1px solid var(--main-color);
            }
          }
        }
      }
    }
  }

  .filter-modal {
    .container {
      width: 90%;
      overflow-y: auto;
      flex-direction: column;
      gap: 16px;
      .header {
        width: 100%;
        padding-bottom: 16px;
        display: flex;
        align-items: center;
        gap: 16px;
        border-bottom: 0.5px solid #eee;
      }

      .body {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 16px;
        flex-wrap: wrap;
        .item {
          // width: 40%;
          flex: 1;
          display: flex;
          align-items: center;
          gap: 16px;

          input[type="checkbox"] {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            width: 40px;
            height: 30px;
            border: 2px solid #ccc;
            border-radius: 4px;
            outline: none;
            cursor: pointer;
            transition: background-color 0.3s ease;
            &:checked {
              background: var(--btn-background);
              &::after {
                content: "\2714";
                font-size: 16px;
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }

          select {
            width: 100%;
            height: 100%;
            color: var(--main-color);
            font-weight: 600;
            border-radius: 10px;
            outline: none;
            border: 2px solid var(--main-color);
            text-align: center;
            background-color: inherit;
            height: 44px;
            cursor: pointer;
            &.checked {
              color: white;
              font-weight: 600;
              border-radius: 10px;
              outline: none;
              border: none;
              text-align: center;
              background-color: inherit;
              height: 44px;
              cursor: pointer;
              background: linear-gradient(91deg, #ff7300 0.18%, #ffcd4d 99.68%);
            }
          }
        }
      }

      .footer {
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 16px;
        .action-btn {
          display: flex;
          width: 141.556px;
          height: 54px;
          padding: 10px;
          justify-content: center;
          align-items: center;
          color: white;
          font-weight: 800;
          gap: 10px;
          border-radius: 10px;
          border: none;
          outline: none;
          background: linear-gradient(91deg, #ff7300 0.18%, #ffcd4d 99.68%);
          cursor: pointer;
          &:nth-child(2) {
            background: white;
            color: var(--main-color);
            font-weight: 600;
            border-radius: 10px;
            outline: none;
            border: 2px solid var(--main-color);
          }
        }
      }
    }
  }
}
