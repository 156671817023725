.admins {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  .container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    background-color: white;
    .spcial-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      width: 100%;
      .search {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;

        .search-container {
          display: flex;
          align-items: center;
          position: relative;
          .icon {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 32px;
            color: rgba(96, 96, 96, 0.25);
          }

          input {
            width: 367px;
            height: 45.6px;
            flex-shrink: 0;
            border-radius: 50px;
            border: 1px solid rgba(24, 24, 24, 0.1);
            box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.25);
            outline: none;
            padding-right: 52px;
          }
        }

        .filter-container {
          display: flex;
          align-items: center;
          gap: 16px;
          cursor: pointer;
          width: 45.6px !important;
          height: 45.6px !important;
          img {
            width: 100%;
            height: 100%;
          }
          .cancel {
            color: var(--main-color);
            cursor: pointer;
          }
        }
      }

      .actions-container {
        display: flex;
        align-items: center;
        gap: 16px;
      }
    }
  }

  .data-grid {
    width: 100%;
    display: flex;
    align-items: center;
    .MuiDataGrid-cell {
      display: flex;
      align-items: center;
    }
    .cell-action {
      display: flex !important;
      align-items: center;
      justify-content: center;
      gap: 16px;
      .cell-btn {
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        .action-btn {
          outline: none;
          border: none;
          padding: 2px 10px;
          font-size: 16px;
          font-weight: 600;
          color: white;
          border-radius: 5px;
          background: var(--btn-background);
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          &:nth-of-type(2) {
            background: white;
            color: var(--main-color);
            border: 1px solid var(--main-color);
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .admins {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .container {
      flex-direction: column;
      .spcial-header {
        flex-direction: column;
        .search {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 16px;
          .search-container {
            width: 100%;
            .icon {
              position: absolute;
              right: 10px;
              top: 50%;
              transform: translateY(-50%);
              font-size: 32px;
              color: rgba(96, 96, 96, 0.25);
            }

            input {
              width: 100%;
              height: 45.6px;
              flex-shrink: 0;
              border-radius: 50px;
              border: 1px solid rgba(24, 24, 24, 0.1);
              box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.25);
              outline: none;
              padding-right: 52px;
            }
          }

          .filter-container {
            width: 100%;
            cursor: pointer;
            width: 45.6px !important;
            height: 45.6px !important;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }

        .actions-container {
          flex-direction: column;
          width: 100%;
        }
      }
    }

    .data-grid {
      width: 100%;
      display: flex;
      align-items: center;
      .MuiDataGrid-cell {
        display: flex;
        align-items: center;
      }
      .cell-action {
        display: flex !important;
        align-items: center;
        justify-content: center;
        gap: 16px;
        .cell-btn {
          display: flex;
          align-items: center;
          gap: 10px;
          cursor: pointer;
          .action-btn {
            outline: none;
            border: none;
            padding: 2px 10px;
            font-size: 16px;
            font-weight: 600;
            color: white;
            border-radius: 5px;
            background: var(--btn-background);
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            &:nth-of-type(2) {
              background: white;
              color: var(--main-color);
              border: 1px solid var(--main-color);
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .admins {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .container {
      flex-direction: column;
      .spcial-header {
        width: 100%;
        flex-direction: column;
        .search {
          width: 100%;
          .search-container {
            width: 100%;
            .icon {
              position: absolute;
              right: 10px;
              top: 50%;
              transform: translateY(-50%);
              font-size: 32px;
              color: rgba(96, 96, 96, 0.25);
            }

            input {
              width: 100%;
              height: 45.6px;
              flex-shrink: 0;
              border-radius: 50px;
              border: 1px solid rgba(24, 24, 24, 0.1);
              box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.25);
              outline: none;
              padding-right: 52px;
            }
          }
          .filter-container {
            width: 100%;
            cursor: pointer;
            width: 45.6px !important;
            height: 45.6px !important;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        .actions-container {
          width: 100% !important;
          justify-content: space-between !important;
        }
      }
    }

    .data-grid {
      width: 100%;
      display: flex;
      align-items: center;
      .MuiDataGrid-cell {
        display: flex;
        align-items: center;
      }
      .cell-action {
        display: flex !important;
        align-items: center;
        justify-content: center;
        gap: 16px;
        .cell-btn {
          display: flex;
          align-items: center;
          gap: 10px;
          cursor: pointer;
          .action-btn {
            outline: none;
            border: none;
            padding: 2px 10px;
            font-size: 16px;
            font-weight: 600;
            color: white;
            border-radius: 5px;
            background: var(--btn-background);
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            &:nth-of-type(2) {
              background: white;
              color: var(--main-color);
              border: 1px solid var(--main-color);
            }
          }
        }
      }
    }
  }
}

.filter-modal {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .container {
    width: 600px !important;

    display: flex;
    flex-direction: column;
    justify-content: flex-start !important;
    background-color: white;
    border-radius: 10px !important;
    gap: 24px;
    .header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start !important;
      padding-bottom: 16px;
      gap: 16px;
      border-bottom: 0.5px solid #eee;
    }

    .body {
      max-width: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;
      .title-container {
        width: 100%;
        max-width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 16px;
        input[type="checkbox"] {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          width: 30px;
          height: 30px;
          border: 2px solid #ccc;
          border-radius: 4px;
          outline: none;
          cursor: pointer;
          transition: background-color 0.3s ease;
          &:checked {
            background: var(--btn-background);
            &::after {
              content: "\2714";
              font-size: 16px;
              color: #fff;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }

      .date-container {
        width: 100%;
        max-width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;
        .from-date {
          flex: 1;
          display: flex;
          align-items: center;
          gap: 16px;
          .select-date {
            padding: 8px 10px;
            border-radius: 10px;
            border-color: #ff7300;
            color: #ff7300;
            font-size: 16px;
            outline: #ffcd4d;
          }
        }
      }
    }

    .footer {
      width: 100%;
      display: flex;
      justify-content: center;
      gap: 16px;
      .action-btn {
        display: flex;
        width: 141.556px;
        height: 40px !important;
        padding: 10px;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: 800;
        gap: 10px;
        border-radius: 10px;
        border: none;
        outline: none;
        background: linear-gradient(91deg, #ff7300 0.18%, #ffcd4d 99.68%);
        cursor: pointer;
        &:nth-child(2) {
          background: white;
          color: var(--main-color);
          font-weight: 600;
          border-radius: 10px;
          outline: none;
          border: 2px solid var(--main-color);
        }
      }
    }
  }
}
