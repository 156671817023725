.chat-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  .container {
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: white;
    border-radius: 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 40px;
    .chat-header {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 16px;
      justify-content: space-between;
      .wrapper {
        display: flex;
        align-items: center;
        gap: 16px;
        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
      }
      .close {
        position: fixed;
        top: 24px;
        left: 24px;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }
    .chats-container {
      display: flex;
      flex-direction: column;
      gap: 24px;
      .chat-body {
        display: flex;
        flex-direction: column;
        p {
          padding: 16px;
          background-color: #ff73002e;
          width: 50%;
          border-radius: 20px 0px 20px 20px;
          font-size: 13px;
          font-weight: 300;
          line-height: 18.85px;
          min-height: 55px;
        }
      }
      .chat-body-replay {
        direction: ltr;
        display: flex;
        flex-direction: column;
        p {
          padding: 16px;
          background: var(--btn-background);
          width: 50%;
          border-radius: 0px 20px 20px 20px;
          color: white;
          font-size: 13px;
          font-weight: 300;
          line-height: 18.85px;
          min-height: 55px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .chat-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    .container {
      width: 100%;
      height: 100%;
      overflow: auto;
      background-color: white;
      border-radius: 0px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 40px;
      .chat-header {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 16px;
        justify-content: space-between;
        .wrapper {
          display: flex;
          align-items: center;
          gap: 16px;
          img {
            width: 40px;
            height: 40px;
          }
        }
        .close {
          display: flex;
          align-items: center;
          cursor: pointer;
        }
      }
      .chats-container {
        display: flex;
        flex-direction: column;
        gap: 24px;
        .chat-body {
          display: flex;
          flex-direction: column;
          p {
            padding: 16px;
            background-color: #ff73002e;
            width: 90%;
            border-radius: 20px 0px 20px 20px;
            font-size: 13px;
            font-weight: 300;
            line-height: 18.85px;
            min-height: 55px;
          }
        }
        .chat-body-replay {
          direction: ltr;
          display: flex;
          flex-direction: column;
          p {
            padding: 16px;
            background: var(--btn-background);
            width: 90%;
            border-radius: 0px 20px 20px 20px;
            color: white;
            font-size: 13px;
            font-weight: 300;
            line-height: 18.85px;
            min-height: 55px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
}
