.outlined {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: 1px solid var(--main-color);
  color: var(--main-color);
  background: white;
  height: 44px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background: var(--btn-background);
    color: white;
  }
}
.fill {
  outline: none;
  border: none;
  background: var(--btn-background);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 767px) {
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .outlined {
    font-size: 12px;
  }
}
