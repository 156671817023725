.statics {
  display: flex;
  flex-direction: column;
  //   margin: auto 0px;
  gap: 24px;
  //   min-height: 80vh;
  table {
    width: 100%;
    border-collapse: collapse;
    font-family: Arial, sans-serif;

    th,
    td {
      padding: 8px;
      text-align: center;
      border: 1px solid #ddd;
    }

    th {
      background-color: #f2f2f2;
    }

    tr:hover {
      background-color: #f5f5f5;
    }
  }
  .actions-container {
    display: flex;
    align-items: center;
    gap: 24px;
  }

  .text-container {
    display: flex;
    align-items: center;
    gap: 24px;
    .text-item {
      height: 40px;
      box-shadow: var(--box-shadow);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      padding: 10px;
      font-size: 16px;
      font-weight: 800;
    }
  }
}
