.widget {
  flex: 0 0 auto;
  flex: 0 0 calc(25% - 15px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 300px;
  padding: 16px 10px;
  gap: 16px;
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  border-radius: 10px;
  .body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .content {
      p {
        &:first-child {
          font-weight: 800;
          font-size: 16px;
        }
      }
    }
  }
  .footer {
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #12121280;
    cursor: pointer;
  }
}

@media (max-width: 767px) {
  .widget {
    width: 100%;
    padding: 16px 10px;
    gap: 16px;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    border-radius: 10px;
    .body {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .content {
        p {
          &:first-child {
            font-weight: 800;
            font-size: 16px;
          }
        }
      }
    }
    .footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #12121280;
      cursor: pointer;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .widget {
    width: calc(50% - 10px);
    padding: 16px 10px;
    gap: 16px;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    border-radius: 10px;
    .body {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .content {
        p {
          &:first-child {
            font-weight: 800;
            font-size: 16px;
          }
        }
      }
    }
    .footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #12121280;
      cursor: pointer;
    }
  }
}
