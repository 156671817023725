.pieChartBox {
  display: flex;
  height: 300px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  border-radius: 8px;
  .pie__chart {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 75%;
    height: 50%;
  }

  .options {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    font-size: 14px;
    .option {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: center;

      .title {
        display: flex;
        gap: 10px;
        align-items: center;

        .dot {
          width: 10px;
          height: 10px;
          border-radius: 50%;
        }
      }
    }
  }
}

@media (max-width: 767px) {
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .pieChartBox {
    display: flex;
    height: 240px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    border-radius: 8px;
    .pie__chart {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 120px;
      height: 120px;
    }

    .options {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      font-size: 14px;
      .option {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;

        .title {
          display: flex;
          gap: 10px;
          align-items: center;

          .dot {
            width: 10px;
            height: 10px;
            border-radius: 50%;
          }
        }
      }
    }
  }
}
