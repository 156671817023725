.main {
  position: relative;
  display: flex;
  flex-direction: column;
  .main-container {
    padding-top: 102px; //90px for header + 12px for padding
    display: flex;
    .main-menu {
      width: 260px;
    }
    .main-content {
      flex: 1;
      border: 1px solid #eee;
      background: #f8f8f8;
      min-height: 100vh;
      overflow: hidden;
    }
  }
}

@media (max-width: 767px) {
  .main {
    position: relative;
    display: flex;
    flex-direction: column;
    .main-container {
      padding-top: 76px;
      display: flex;
      .main-menu {
        width: 70px;
      }
      .main-content {
        flex: 1;
        border: 1px solid #eee;
        background: #f8f8f8;
        min-height: 100vh;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .main {
    position: relative;
    display: flex;
    flex-direction: column;
    .main-container {
      padding-top: 90px;
      display: flex;
      .main-menu {
        width: 200px;
      }
      .main-content {
        flex: 1;
        border: 1px solid #eee;
        background: #f8f8f8;
        min-height: 100vh;
      }
    }
  }
}
