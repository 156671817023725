.create {
  width: 100%;
  height: 100%;
  .container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    form {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      .control-group {
        display: flex;
        align-items: center;
        gap: 16px;
        flex-wrap: wrap;
        .add-input {
          width: 90px;
          height: 80px;
          text-align: center;
          border-radius: 16px;
          border: none;
          outline: none;
          background-color: #f5f5f580;
          padding: 24px 16px 24px 16px;
        }
        label:has(+ [required]) {
          &::after {
            content: "*";
            color: red;
          }
        }
        .form-input {
          width: 100% !important;
          input {
            width: 100% !important;
            height: auto;
            background-color: white;
            padding: 16.5px 14px;
            text-align: right;
          }
        }
        .form-label {
          margin-bottom: 5px;
          &::after {
            content: "*";
            color: red;
          }
        }
      }

      .form-actions {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }
}

@media (max-width: 767px) {
  .create {
    .container {
      .header {
        flex-direction: column;
        h3 {
          align-self: flex-start;
        }
      }
      form {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        .control-group {
          width: 100%;
          justify-content: center;

          flex-direction: column;
          .select {
            width: 100%;
          }

          input {
            height: 40px !important;
            text-align: center;
            border-radius: 16px;
            border: none;
            outline: none;
            background-color: #f5f5f580;
            padding: 8px;
          }
          label:has(+ [required]) {
            &::after {
              content: "*";
              color: red;
            }
          }
          .form-input {
            width: 100% !important;
            input {
              width: 100% !important;
              height: auto !important;
              background-color: white;
              padding: 16.5px 14px;
            }
          }
        }

        .form-actions {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
}
