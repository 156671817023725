.contact {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  .container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    background-color: white;
    .spcial-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      width: 100%;

      .actions-container {
        display: flex;
        align-items: center;
        gap: 16px;
      }
    }
  }

  .data-grid {
    width: 100%;
    display: flex;
    align-items: center;
    .data-grid .MuiDataGrid-root {
      height: 100%;
    }
    .MuiDataGrid-cell {
      display: flex;
      align-items: center;
    }
    .cell-action {
      display: flex !important;
      align-items: center;
      justify-content: center;
      gap: 16px;
      .cell-btn {
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        .action-btn {
          outline: none;
          border: none;
          padding: 2px 10px;
          font-size: 16px;
          font-weight: 600;
          color: white;
          border-radius: 5px;
          background: var(--btn-background);
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          &:nth-of-type(2) {
            background: white;
            color: var(--main-color);
            border: 1px solid var(--main-color);
          }
        }
      }
      .outlined {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid var(--main-color);
        color: var(--main-color);
        background: white;
        height: 44px;
        font-size: 16px;
        font-weight: 600;
        border-radius: 5px;
        cursor: pointer;
        &:hover {
          background: var(--btn-background);
          color: white;
        }
      }
      .fill {
        outline: none;
        border: none;
        background: var(--btn-background);
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .MuiInputBase-root {
    display: none;
  }
  button.MuiButtonBase-root {
    &:nth-child(2) {
      display: none;
    }
  }
}

@media (max-width: 767px) {
  .contact {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .container {
      flex-direction: column;
      .spcial-header {
        flex-direction: column;

        .actions-container {
          flex-direction: column;
          width: 100%;
        }
      }
    }

    .data-grid {
      width: 100%;
      display: flex;
      align-items: center;
      .MuiDataGrid-cell {
        display: flex;
        align-items: center;
      }
      .cell-action {
        display: flex !important;
        align-items: center;
        justify-content: center;
        gap: 16px;
        .cell-btn {
          display: flex;
          align-items: center;
          gap: 10px;
          cursor: pointer;
          .action-btn {
            outline: none;
            border: none;
            padding: 2px 10px;
            font-size: 16px;
            font-weight: 600;
            color: white;
            border-radius: 5px;
            background: var(--btn-background);
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            &:nth-of-type(2) {
              background: white;
              color: var(--main-color);
              border: 1px solid var(--main-color);
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .contact {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .container {
      flex-direction: column;
      .spcial-header {
        width: 100%;
        flex-direction: column;
        .actions-container {
          width: 100% !important;
          justify-content: space-between !important;
        }
      }
    }

    .data-grid {
      width: 100%;
      display: flex;
      align-items: center;
      .MuiDataGrid-cell {
        display: flex;
        align-items: center;
      }
      .cell-action {
        display: flex !important;
        align-items: center;
        justify-content: center;
        gap: 16px;
        .cell-btn {
          display: flex;
          align-items: center;
          gap: 10px;
          cursor: pointer;
          .action-btn {
            outline: none;
            border: none;
            padding: 2px 10px;
            font-size: 16px;
            font-weight: 600;
            color: white;
            border-radius: 5px;
            background: var(--btn-background);
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            &:nth-of-type(2) {
              background: white;
              color: var(--main-color);
              border: 1px solid var(--main-color);
            }
          }
        }
      }
    }
  }
}
