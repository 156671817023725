.training-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .actions-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
  .data-grid {
    width: 100%;
    display: flex;
    align-items: center;
    .MuiDataGrid-cell {
      display: flex;
      align-items: center;
    }
    .cell-action {
      display: flex !important;
      align-items: center;
      justify-content: center;
      gap: 16px;
      .cell-btn {
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        .action-btn {
          outline: none;
          border: none;
          padding: 2px 10px;
          font-size: 16px;
          font-weight: 600;
          color: white;
          border-radius: 5px;
          background: var(--btn-background);
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          &:nth-of-type(2) {
            background: white;
            color: var(--main-color);
            border: 1px solid var(--main-color);
          }
        }
      }
    }
  }
}
