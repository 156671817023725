.rates {
  display: flex;
  flex-direction: column;
  gap: 24px;
  .rate {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: var(--box-shadow);
    border-radius: 10px;
    padding: 16px;
    .rate-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .text-container {
        display: flex;
        align-items: center;
        gap: 24px;
        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
        .wrapper {
          display: flex;
          flex-direction: column;
          span {
            font-size: 14px;
            font-weight: 400;
            line-height: 22.5px;
            text-align: right;
            &:last-child {
              color: #a4acad;
            }
          }
          .star-container {
            img {
              width: 20px;
              height: 20px;
            }
          }
        }
      }

      .actions-container {
        display: flex;
        align-items: center;
        gap: 16px;
        div {
          cursor: pointer;
          display: flex;
          align-items: center;
        }
        input[type="checkbox"] {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          width: 30px;
          height: 30px;
          border: 2px solid #ccc;
          border-radius: 4px;
          outline: none;
          cursor: pointer;
          transition: background-color 0.3s ease;
          &:checked {
            background: var(--btn-background);
            &::after {
              content: "\2714";
              font-size: 16px;
              color: #fff;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
    .rate-body {
    }
  }
}
