.view {
  width: 100%;
  height: 100%;
  .container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .actions-container {
        display: flex;
        align-items: center;
        gap: 24px;
      }
    }
    .accordions-container {
      display: flex;
      flex-direction: column;
      gap: 24px;
      .MuiPaper-root {
        box-shadow: var(--box-shadow) !important;
        border-radius: 15px;
        position: static;
      }

      .tabels {
        display: flex;
        padding: 24px;
        table {
          flex: 1;
          width: 50%;
          border-collapse: collapse;
          th,
          td {
            border: 1px solid #ddd;
            text-align: center;
            padding: 8px;
          }

          th {
            background-color: #4caf50;
            color: white;
          }

          tr:nth-child(even) {
            background-color: #f2f2f2;
          }
        }
      }

      .certificates {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        .header {
          width: 100%;
          display: flex;
          justify-content: flex-end;
        }
        table {
          width: 80%;
          border-collapse: collapse;
          th,
          td {
            border: 1px solid #ddd;
            text-align: center;
            padding: 8px;
            width: 50%;
          }

          th {
            background-color: #4caf50;
            color: white;
            width: 50%;
          }

          tr:nth-child(even) {
            background-color: #f2f2f2;
          }
        }
        .certificates-container {
          display: flex;
          flex-direction: column;
          gap: 24px;
          width: 100%;
          .img-container {
            display: flex;
            flex-wrap: wrap;
          }
        }

        .actions-container {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
      }
    }
  }
}

.edit {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  .edit-container {
    width: 40%;
    height: auto;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    .header {
      width: 100%;
    }
    .body {
      width: 100%;
      display: flex;
      flex-direction: column;
      border-radius: 4px;
      box-shadow: var(--box-shadow);
      .form-select {
        width: 100%;
        padding: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #ccc;
        input[type="radio"] {
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          width: 20px;
          height: 20px;
          border: 2px solid #ccc;
          border-radius: 50%;
          outline: none;
          position: relative;
          cursor: pointer;
          &:checked {
            border-color: var(--main-color);
            &:before {
              content: "";
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 10px;
              height: 10px;
              border-radius: 50%;
              background: var(--btn-background);
            }
          }
        }
      }
    }
    .actions-container {
      width: fit-content;
      align-self: flex-end;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}

@media (max-width: 767px) {
  .view {
    .container {
      .header {
        flex-direction: column;
        gap: 16px;
        .actions-container {
          width: 100%;
          display: flex;
          align-items: center;
          gap: 24px;
          flex-direction: column;
        }
      }
      .accordions-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 24px;
        .MuiPaper-root {
          box-shadow: var(--box-shadow) !important;
          border-radius: 15px;
          position: static;
        }

        .tabels {
          flex-direction: column;
          padding: 0%;
          table {
            width: 100%;

            th,
            td {
              font-size: 10px;

              width: 50%;
            }

            th {
              width: 50%;
              font-size: 10px;
            }

            tr:nth-child(even) {
              background-color: #f2f2f2;
            }
          }
        }

        .statics {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 24px;
          table {
            width: 100%;
            border-collapse: collapse;
            font-family: Arial, sans-serif;

            th,
            td {
              font-size: 8px;
              padding: 8px;
              text-align: center;
              border: 1px solid #ddd;
            }

            th {
              font-size: 8px;
              background-color: #f2f2f2;
            }

            tr:hover {
              background-color: #f5f5f5;
            }
          }
          .actions-container {
            width: 100%;
            flex-direction: column;
            gap: 16px;
          }

          .text-container {
            display: flex;
            align-items: center;
            gap: 24px;
            .text-item {
              height: 30px;
              box-shadow: var(--box-shadow);
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 10px;
              padding: 10px;
              font-size: 12px;
              font-weight: 600;
            }
          }
        }

        .levels {
          display: flex;
          flex-direction: column;
          gap: 24px;
          table {
            width: 100%;
            border-collapse: collapse;
            font-family: Arial, sans-serif;

            th,
            td {
              font-size: 8px;
              padding: 8px;
              text-align: center;
              border: 1px solid #ddd;
            }

            th {
              font-size: 8px;
              background-color: #f2f2f2;
            }

            tr:hover {
              background-color: #f5f5f5;
            }
          }
        }

        .certificates {
          width: 100%;
          gap: 16px;
          .header {
            width: 100%;
          }
          table {
            width: 100%;
            border-collapse: collapse;
            th,
            td {
              border: 1px solid #ddd;
              text-align: center;
              padding: 8px;
              width: 50%;
            }

            th {
              background-color: #4caf50;
              color: white;
              width: 50%;
            }

            tr:nth-child(even) {
              background-color: #f2f2f2;
            }
          }
          .certificates-container {
            display: flex;
            flex-direction: column;
            gap: 24px;
            width: 100%;
            .img-container {
              width: 100%;
              display: flex;
              flex-wrap: wrap;
              img {
                width: 100%;
              }
            }
          }

          .actions-container {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
          }
        }

        .close-friends {
          width: 100%;
          table {
            width: 100%;
            border-collapse: collapse;
            font-family: Arial, sans-serif;

            th,
            td {
              font-size: 8px;
              padding: 8px;
              text-align: center;
              border: 1px solid #ddd;
            }

            th {
              font-size: 8px;
              background-color: #f2f2f2;
            }

            tr:hover {
              background-color: #f5f5f5;
            }
          }
        }
      }
    }
  }

  .edit {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    .edit-container {
      width: 90%;
      height: auto;
      background-color: white;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      gap: 24px;
      .header {
        width: 100%;
      }
      .body {
        width: 100%;
        display: flex;
        flex-direction: column;
        border-radius: 4px;
        box-shadow: var(--box-shadow);
        .form-select {
          width: 100%;
          padding: 8px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid #ccc;
          input[type="radio"] {
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            width: 20px;
            height: 20px;
            border: 2px solid #ccc;
            border-radius: 50%;
            outline: none;
            position: relative;
            cursor: pointer;
            &:checked {
              border-color: var(--main-color);
              &:before {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: var(--btn-background);
              }
            }
          }
        }
      }
      .actions-container {
        width: fit-content;
        align-self: flex-end;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
}
