.modal {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .container {
    width: 600px;
    gap: 24px;
    background-color: white;
    border-radius: 20px;
    background: #fff;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;

    .header {
      width: 100%;
      padding-bottom: 16px;
      display: flex;
      align-items: center;
      gap: 16px;
      border-bottom: 0.5px solid #eee;
    }

    .body {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 16px;
      flex-wrap: wrap;
      .item {
        flex: auto;
        max-width: 170px !important;
        display: flex;
        align-items: center;
        gap: 16px;
        // flex: 0 0 auto;
        input[type="checkbox"] {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          width: 40px;
          height: 30px;
          border: 2px solid #ccc;
          border-radius: 4px;
          outline: none;
          cursor: pointer;
          transition: background-color 0.3s ease;
          &:checked {
            background: var(--btn-background);
            border: none;
            outline: none;
            &::after {
              content: "\2714";
              font-size: 16px;
              color: #fff;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }

        select {
          width: 100%;
          height: 100%;
          color: var(--main-color);
          font-size: 12px;
          border-radius: 10px;
          outline: none;
          border: 2px solid var(--main-color);
          text-align: center;
          background-color: inherit;
          height: 44px;
          cursor: pointer;
          &.checked {
            color: white;
            font-weight: 600;
            border-radius: 10px;
            outline: none;
            border: none;
            text-align: center;
            background-color: inherit;
            height: 44px;
            cursor: pointer;
            background: linear-gradient(91deg, #ff7300 0.18%, #ffcd4d 99.68%);
            option {
              color: var(--main-color);
            }
          }
        }
      }
    }

    .footer {
      width: 100%;
      display: flex;
      justify-content: center;
      gap: 16px;
      .action-btn {
        display: flex;
        width: 141.556px;
        height: 54px;
        padding: 10px;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: 800;
        gap: 10px;
        border-radius: 10px;
        border: none;
        outline: none;
        background: linear-gradient(91deg, #ff7300 0.18%, #ffcd4d 99.68%);
        cursor: pointer;
        &:nth-child(2) {
          background: white;
          color: var(--main-color);
          font-weight: 600;
          border-radius: 10px;
          outline: none;
          border: 2px solid var(--main-color);
        }
      }
    }
  }
}
