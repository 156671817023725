.terms-and-conditions {
  height: 100%;
  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 24px;
    .actions-container {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 24px;
      .action-link {
        flex: 1;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-decoration: none;
        color: #111111;
        box-shadow: var(--box-shadow);
        border-radius: 10px;
        font-size: 18px;
        .icon {
          color: #1f1f1f80;
          font-size: 18px;
        }
        &:hover {
          background: var(--btn-background);
          color: white;
          .icon {
            color: white;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .terms-and-conditions {
    .container {
      .actions-container {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 24px;
        .action-link {
          flex: 1;
          width: 100%;
          padding: 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          text-decoration: none;
          color: #111111;
          box-shadow: var(--box-shadow);
          border-radius: 10px;
          font-size: 18px;
          .icon {
            color: #1f1f1f80;
            font-size: 18px;
          }
          &:hover {
            background: var(--btn-background);
            color: white;
            .icon {
              color: white;
            }
          }
        }
      }
    }
  }
  .modal {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .message {
      width: 90%;
      height: auto;
      top: 366px;
      left: 445px;
      gap: 0px;
      border-radius: 20px;
      opacity: 0px;
      background-color: white;
      padding: 24px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 24px;
      .header {
        display: flex;
        align-items: center;
        gap: 24px;
      }
      .body {
        display: flex;
        flex-direction: column;
        gap: 16px;
        .form-select {
          display: flex;
          align-items: center;
          gap: 16px;
          label {
            display: block;
            margin-bottom: 8px;
            color: #111;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            order: 1;
          }
          input[type="radio"] {
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            width: 20px;
            height: 20px;
            border: 2px solid #ccc;
            border-radius: 50%;
            outline: none;
            position: relative;
            &:checked {
              border-color: var(--main-color);
              &:before {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: var(--btn-background);
              }
            }
          }
        }
      }
      .footer {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .create {
    .container {
    }
  }
}
