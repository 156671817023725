.home {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .container {
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 24px;
    .widgets {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
    }
    .charts {
      display: flex;
      align-items: center;
      gap: 16px;
      .left-chart {
        flex: 1;
      }
      .right-chart {
        flex: 3;
      }
    }
  }
}

@media (max-width: 767px) {
  .home {
    .container {
      .charts {
        .left-chart {
          flex: 1;
        }
        .right-chart {
          display: none;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .home {
    .container {
      .widgets {
        justify-content: flex-start;
        width: 100%;
      }
      .charts {
        flex-direction: column;
        .left-chart {
          width: 100%;
        }
        .right-chart {
          width: 100%;
        }
      }
    }
  }
}
