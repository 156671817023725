.edit {
  width: 100%;
  height: 100%;
  .container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .actions-container {
        display: flex;
        align-items: center;
        gap: 16px;
      }
    }
    form {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      .control-group {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 24px;
        .form-control {
          flex: 1;
          display: flex;
          flex-direction: column;
          gap: 8px;
          label:has(+ [required]) {
            display: block;
            &::after {
              content: "*";
              color: red;
            }
          }
          input {
            width: 100%;
            padding: 16px;
            border-radius: 16px;
            background-color: #f5f5f540;
            border: none;
            outline: none;
            box-shadow: var(--box-shadow);
            &::placeholder {
              color: rgba(17, 17, 17, 0.4);
              font-weight: 600;
            }
          }
        }
      }

      .form-actions {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }
}

@media (max-width: 767px) {
  .edit {
    .container {
      .header {
        flex-direction: column;
        h3 {
          align-self: flex-start;
        }
      }
      form {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        .control-group {
          flex-direction: column;
          .form-control {
            width: 100%;
            label:has(+ [required]) {
              display: block;
              &::after {
                content: "*";
                color: red;
              }
            }
            input {
              width: 100%;
              padding: 16px;
              border-radius: 16px;
              background-color: #f5f5f540;
              border: none;
              outline: none;
              box-shadow: var(--box-shadow);
              &::placeholder {
                color: rgba(17, 17, 17, 0.4);
                font-weight: 600;
              }
            }
          }
        }

        .form-actions {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
}
