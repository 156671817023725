.chart {
  margin: 20px 0px;
  padding: 20px;
  height: 300px;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    .chart-title {
      font-size: 14px;
      font-weight: 600;
    }
  }
}
