.edit-admin {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  .container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    form {
      gap: 16px;
      display: flex;
      flex-direction: column;
      .control-group {
        display: flex;
        align-items: center;
        gap: 16px;
        flex-wrap: wrap;
        .add-input {
          width: 90px;
          height: 80px;
          text-align: center;
          border-radius: 16px;
          border: none;
          outline: none;
          background-color: #f5f5f580;
          padding: 24px 16px 24px 16px;
        }
        label:has(+ [required]) {
          &::after {
            content: "*";
            color: red;
          }
        }
        .form-input {
          width: 100% !important;
          input {
            width: 100% !important;
            height: auto;
            background-color: white;
            padding: 16.5px 14px;
            text-align: right;
          }
        }
        .form-label {
          &::after {
            content: "*";
            color: red;
          }
        }
      }

      .check-group {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
        .check {
          width: calc(25% - 18px);
          display: flex;
          flex-direction: column;
          gap: 16px;
          box-shadow: var(--box-shadow);
          border-radius: 10px;
          .header {
            font-size: 14px;
            font-weight: 400;
            padding: 8px 16px;
            border-bottom: 1px solid #ccc;
          }
          .body {
            display: flex;
            flex-direction: column;
            padding: 8px 16px;
            gap: 16px;
            .item {
              display: flex;
              align-items: center;
              gap: 16px;
              label {
                font-size: 12px;
                font-weight: 600;
              }
              input[type="checkbox"] {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                width: 24px;
                height: 24px;
                border: 2px solid #ccc;
                border-radius: 4px;
                outline: none;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                transition: background-color 0.3s ease;
                &:checked {
                  background: var(--btn-background);
                  &::after {
                    content: "\2714";
                    font-size: 16px;
                    color: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  }
                }
              }
            }
          }
        }
      }

      .action-control {
        align-self: flex-end;
      }
    }
  }
}

@media (max-width: 767px) {
  .edit-admin {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .container {
      display: flex;
      flex-direction: column;
      gap: 16px;
      form {
        width: 100%;
        .control-group {
          width: 100%;
          justify-content: center;
          flex-direction: column;
          .select {
            width: 100%;
          }
          .form-input {
            width: 100% !important;
          }
        }

        .check-group {
          .check {
            width: 100%;
          }
        }
        .action-control {
          align-self: flex-end;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
}
