.login {
  width: 100%;
  height: 110vh;
  .container {
    width: 100%;
    height: 100%;
    padding: 0px;
    display: flex;
    overflow: hidden;
    .img-container {
      flex: 1;
      height: 100%;
      width: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .form-container {
      flex: 1;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      form {
        width: 80%;
        height: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 24px;
        .message {
          color: #777;
        }
        .control-group {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 24px;

          .form-control {
            position: relative;
            .second-icon {
              position: absolute;
              top: 50%;
              transform: translate(-50%);
              left: 20px;
              display: flex;
              color: #c1c1c1;
              font-size: 28px;
              border-radius: 50%;
              cursor: pointer;
              &:hover {
                background-color: rgba($color: #000000, $alpha: 0.5);
              }
            }
            label {
              display: block;
              margin-bottom: 8px;
              color: #777;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
            input {
              width: 100%;
              outline: none;
              border: none;
              height: 40px;
              padding: 0px 10px;
              font-size: 18px;
              font-weight: 600;
              box-shadow: 0 0 4px 0 rgba(0, 0, 0, 16%);
              border-radius: 5px;
            }
          }

          .form-select {
            display: flex;
            align-items: center;
            gap: 16px;
            label {
              display: block;
              margin-bottom: 8px;
              color: #111;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              order: 1;
            }
            input[type="radio"] {
              appearance: none;
              -webkit-appearance: none;
              -moz-appearance: none;
              width: 20px;
              height: 20px;
              border: 2px solid #ccc;
              border-radius: 50%;
              outline: none;
              position: relative;
              &:checked {
                border-color: var(--main-color);
                &:before {
                  content: "";
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  width: 10px;
                  height: 10px;
                  border-radius: 50%;
                  background: var(--btn-background);
                }
              }
            }
          }
        }

        .form-action {
          width: 100%;
          .action-btn {
            width: 100%;
            outline: none;
            border: none;
            height: 40px;
            padding: 0px 10px;
            font-size: 18px;
            font-weight: 600;
            color: white;
            border-radius: 5px;
            background: var(--btn-background);
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        a {
          text-decoration: none;
          color: var(--main-color) !important;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .login {
    .container {
      .img-container {
        display: none;
      }
      .form-container {
        form {
          width: 90%;
          .title {
            font-size: 24px;
          }
          gap: 16px;
          .message {
            font-size: 12px;
          }
          .control-group {
            gap: 16px;
            .form-control {
              label {
                font-size: 12px;
              }
              input {
                font-size: 16px;
                font-weight: 400;
              }
            }

            .form-select {
              display: flex;
              align-items: center;
              gap: 16px;
              label {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .login {
    .container {
      .img-container {
        flex: 1.5;
      }
      .form-container {
        flex: 2;
        form {
          width: 90%;
          .title {
            font-size: 24px;
          }
          gap: 16px;
          .message {
            font-size: 12px;
          }
          .control-group {
            gap: 16px;
            .form-control {
              label {
                font-size: 12px;
              }
              input {
                font-size: 16px;
                font-weight: 400;
              }
            }

            .form-select {
              display: flex;
              align-items: center;
              gap: 16px;
              label {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}
