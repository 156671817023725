.edit {
  height: 100%;
  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    form {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 24px;
      img {
        width: 240px;
        height: 200px;
        border-radius: 4px;
      }
      .form-control {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        input[type="file"] {
          display: none;
        }
        .file-label {
          display: flex;
          align-items: center;
          margin: 0px auto;
          border-radius: 10px;
          height: 44px;
          gap: 16px;
          box-shadow: 0px 0px 0px 2px rgba(201, 201, 201, 0.47);
          padding: 0px 15px;
          cursor: pointer;
          .icon {
            font-weight: bolder;
            font-size: 16px;
          }
        }
        .image-container {
          margin: 0px auto;
        }
        span {
          display: flex;
          align-items: center;
          gap: 10px;
          .icon {
            color: blue;
            cursor: pointer;

            width: 20px;
            height: 20px;
            &:hover {
              border-radius: 50%;
              border: 1px solid #777;
            }
          }
        }
        input {
          align-self: flex-start;
          width: 50%;
          height: 44px;
          box-shadow: var(--box-shadow);
          padding: 0px 16px;
          border: none;
          outline: none;
          background-color: #f5f5f540;
          border-radius: 10px;
        }
      }
      // .file-control {
      //   width: 100%;
      //   display: flex;
      //   flex-direction: column;
      //   align-items: center;
      // }
    }
  }
}

.modal {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .message {
    width: 550px;
    height: 292px;
    top: 366px;
    left: 445px;
    gap: 0px;
    border-radius: 20px;
    opacity: 0px;
    background-color: white;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 24px;
    .header {
      display: flex;
      align-items: center;
      gap: 24px;
    }
    .body {
      display: flex;
      flex-direction: column;
      gap: 16px;
      .form-select {
        display: flex;
        align-items: center;
        gap: 16px;
        label {
          display: block;
          margin-bottom: 8px;
          color: #111;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          order: 1;
        }
        input[type="radio"] {
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          width: 20px;
          height: 20px;
          border: 2px solid #ccc;
          border-radius: 50%;
          outline: none;
          position: relative;
          &:checked {
            border-color: var(--main-color);
            &:before {
              content: "";
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 10px;
              height: 10px;
              border-radius: 50%;
              background: var(--btn-background);
            }
          }
        }
      }
    }
    .footer {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }
}

@media (max-width: 767px) {
  .edit {
    height: 100%;
    .container {
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 24px;
      .header {
        .title {
          font-size: 12px;
        }
      }
      form {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 24px;
        .form-control {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 24px;
          input[type="file"] {
            display: none;
          }
          .file-label {
            display: flex;
            align-items: center;
            border-radius: 10px;
            height: 44px;
            gap: 16px;
            box-shadow: 0px 0px 0px 2px rgba(201, 201, 201, 0.47);
            padding: 0px 15px;
            cursor: pointer;
            .icon {
              font-weight: bolder;
              font-size: 16px;
            }
          }
          span {
            display: flex;
            align-items: center;
            gap: 10px;
            .icon {
              color: blue;
              cursor: pointer;

              width: 20px;
              height: 20px;
              &:hover {
                border-radius: 50%;
                border: 1px solid #777;
              }
            }
          }
          input {
            align-self: flex-start;
            width: 100%;
            height: 44px;
            box-shadow: var(--box-shadow);
            padding: 0px 16px;
            border: none;
            outline: none;
            background-color: #f5f5f540;
            border-radius: 10px;
          }
        }
      }
    }
  }

  .modal {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .message {
      width: 90%;
      height: auto;
      top: 366px;
      left: 445px;
      gap: 0px;
      border-radius: 20px;
      opacity: 0px;
      background-color: white;
      padding: 24px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 24px;
      .header {
        display: flex;
        align-items: center;
        gap: 24px;
      }
      .body {
        display: flex;
        flex-direction: column;
        gap: 16px;
        .form-select {
          display: flex;
          align-items: center;
          gap: 16px;
          label {
            display: block;
            margin-bottom: 8px;
            color: #111;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            order: 1;
          }
          input[type="radio"] {
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            width: 20px;
            height: 20px;
            border: 2px solid #ccc;
            border-radius: 50%;
            outline: none;
            position: relative;
            &:checked {
              border-color: var(--main-color);
              &:before {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: var(--btn-background);
              }
            }
          }
        }
      }
      .footer {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
}
