.chats {
  display: flex;
  flex-direction: column;
  gap: 24px;
  .chat {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: var(--box-shadow);
    border-radius: 10px;
    padding: 16px;
    .chat-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .text-container {
        display: flex;
        align-items: center;
        gap: 24px;
        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
        }
        .wrapper {
          display: flex;
          flex-direction: column;
          span {
            font-size: 14px;
            font-weight: 400;
            line-height: 22.5px;
            text-align: right;
            &:last-child {
              color: #a4acad;
            }
          }
        }
      }
      .actions-container {
        display: flex;
        align-items: center;
        gap: 16px;
        div {
          cursor: pointer;
          display: flex;
          align-items: center;
        }
      }
    }
    .chat-body {
    }
  }
}
