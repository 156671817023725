.photos-videos {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;
  min-height: 60vh;
  .actions-container {
    width: 100%;
    display: flex;
    gap: 16px;
    justify-content: center;
    align-items: center;
  }
  .photos-videos-container {
    height: 100%;
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
    // justify-content: space-between;
    .photo-container {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      .photo {
        width: 225px;
        height: 230px;
      }
      .icon {
        position: absolute;
        cursor: pointer;
        width: 50px;
        height: 50px;
      }
    }

    .video-container {
      position: relative;
      display: flex;
      gap: 24px;
      align-items: center;
      .video {
        max-width: 240px;
        max-height: 220px;
      }
      .icon {
        z-index: 10;
        position: absolute;
        top: 10px;
        left: 10px;
        cursor: pointer;
        width: 30px;
        height: 30px;
      }
    }
  }
}
.gallery-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  .container {
    width: 500px;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;
    gap: 24px;
    .uploading {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;
      justify-content: center;
      align-items: center;
    }
    form {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 24px;
      .form-control {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        input[type="file"] {
          display: none;
        }
        label {
          display: flex;
          align-items: center;
          border-radius: 10px;
          gap: 16px;
          box-shadow: 0px 0px 0px 2px rgba(201, 201, 201, 0.47);
          padding: 15px;
          cursor: pointer;
          .icon {
            font-weight: bolder;
            font-size: 16px;
          }
        }
        span {
          display: flex;
          align-items: center;
          gap: 10px;
          .icon {
            color: blue;
            cursor: pointer;

            width: 20px;
            height: 20px;
            &:hover {
              border-radius: 50%;
              border: 1px solid #777;
            }
          }
        }
      }

      .control-group {
        width: 100%;
        display: flex;

        gap: 24px;
        .form-select {
          display: flex;
          align-items: center;
          gap: 16px;
          .select-label {
            display: block;
            margin-bottom: 8px;
            color: #111;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            order: 1;
          }
          input[type="radio"] {
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            width: 20px;
            height: 20px;
            border: 2px solid #ccc;
            border-radius: 50%;
            outline: none;
            position: relative;
            cursor: pointer;
            &:checked {
              border-color: var(--main-color);
              &:before {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: var(--btn-background);
              }
            }
          }
        }
      }
    }
  }
}
