.add-question {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    form {
      gap: 16px;
      display: flex;
      flex-direction: column;
      .control-group {
        display: flex;
        align-items: center;
        gap: 16px;
        flex-wrap: wrap;
        .add-input {
          width: 90px;
          height: 80px;
          text-align: center;
          border-radius: 16px;
          border: none;
          outline: none;
          background-color: #f5f5f580;
          padding: 24px 16px 24px 16px;
        }
        label:has(+ [required]) {
          &::after {
            content: "*";
            color: red;
          }
        }
        .form-input {
          width: 100% !important;
          input {
            width: 100% !important;
            height: auto;
            background-color: white;
            padding: 16.5px 14px;
            text-align: right;
          }
        }
        .form-label {
          &::after {
            content: "*";
            color: red;
          }
        }
      }

      .action-control {
        align-self: flex-end;
      }
    }
  }
}

@media (max-width: 767px) {
  .add-question {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .container {
      display: flex;
      flex-direction: column;
      gap: 16px;
      form {
        width: 100%;
        .control-group {
          width: 100%;
          justify-content: center;
          &:not(:first-child) {
            flex-direction: column;
            .select {
              width: 100%;
            }
          }
          input {
            width: 50px !important;
            height: 40px !important;
            text-align: center;
            border-radius: 16px;
            border: none;
            outline: none;
            background-color: #f5f5f580;
            padding: 8px;
          }
          label:has(+ [required]) {
            &::after {
              content: "*";
              color: red;
            }
          }
          .form-input {
            width: 100% !important;
            input {
              width: 100% !important;
              height: auto !important;
              background-color: white;
              padding: 16.5px 14px;
            }
          }
        }

        .action-control {
          align-self: flex-end;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
}
