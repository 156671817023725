.real-chat-modal {
  .chat-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    .top {
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      // border-radius: 10px;
      box-shadow: var(--box-shadow);
      .user {
        display: flex;
        align-items: center;
        gap: 20px;

        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          cursor: pointer;
        }

        .texts {
          display: flex;
          flex-direction: column;
          gap: 5px;

          span {
            font-size: 18px;
            font-weight: bold;
          }

          p {
            font-size: 14px;
            font-weight: 300;
            color: #a5a5a5;
          }
        }
      }

      img {
        cursor: pointer;
      }
    }

    .center {
      scrollbar-width: none;
      width: 100%;
      padding: 20px;
      flex: 1;
      overflow: auto;
      display: flex;
      flex-direction: column;
      gap: 20px;

      .message {
        max-width: 70%;
        display: flex;
        gap: 20px;

        &.own {
          align-self: flex-end;
          .texts {
            width: 100%;
            overflow-wrap: break-word;
            word-wrap: break-word;
            p {
              background-color: #f0f2f5;
              color: black;
            }
          }
        }

        img {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          object-fit: cover;
        }
        .texts {
          flex: 1;
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 5px;
          overflow-wrap: break-word;
          word-wrap: break-word;
          p {
            padding: 20px;
            background-color: #5183fe;
            color: white;
            border-radius: 10px;
            overflow-wrap: break-word;
            word-wrap: break-word;
          }

          span {
            font-size: 13px;
          }
        }
      }
    }

    .bottom {
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid #dddddd35;
      gap: 20px;
      margin-top: auto;

      img {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }

      input {
        flex: 1;
        background-color: #f0f2f5;
        border: none;
        outline: none;
        color: black;
        padding: 20px;
        border-radius: 10px;
        font-size: 16px;
        &:disabled {
          cursor: not-allowed;
        }
      }

      .emoji {
        position: relative;

        .picker {
          position: absolute;
          bottom: 50px;
          right: 0;
        }
      }

      .sendButton {
        background-color: #5183fe;
        color: white;
        padding: 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;

        &:disabled {
          background-color: #5182feb4;
          cursor: not-allowed;
        }
      }
    }
  }
}

// .real-chat-modal {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   .container {
//     width: 100%;
//     height: 100%;
//     overflow: auto;
//     background-color: white;
//     border-radius: 0px;
//     display: flex;
//     flex-direction: column;
//     justify-content: flex-start;
//     gap: 40px;
//     .chat-header {
//       width: 100%;
//       display: flex;
//       align-items: center;
//       gap: 16px;
//       justify-content: space-between;
//       .wrapper {
//         display: flex;
//         align-items: center;
//         gap: 16px;
//         img {
//           width: 40px;
//           height: 40px;
//           border-radius: 50%;
//         }
//       }
//       .close {
//         position: fixed;
//         top: 24px;
//         left: 24px;
//         display: flex;
//         align-items: center;
//         cursor: pointer;
//       }
//     }
//     .chats-container {
//       flex: 1;
//       display: flex;
//       flex-direction: column;
//       gap: 24px;
//       .chat-body {
//         display: flex;
//         flex-direction: column;

//         .chat {
//           padding: 16px;
//           background-color: #ff73002e;
//           width: 50%;
//           border-radius: 20px 0px 20px 20px;
//           font-size: 13px;
//           font-weight: 300;
//           line-height: 18.85px;
//           min-height: 55px;
//           word-wrap: break-word;
//           overflow-wrap: break-word;
//           &:last-child {
//             display: block;
//           }
//         }
//       }
//       .chat-body-replay {
//         direction: ltr;
//         display: flex;
//         flex-direction: column;
//         .chat {
//           padding: 16px;
//           background: var(--btn-background);
//           width: 50%;
//           border-radius: 0px 20px 20px 20px;
//           color: white;
//           font-size: 13px;
//           font-weight: 300;
//           line-height: 18.85px;
//           min-height: 55px;
//         }
//       }
//     }
//     .chat-footer {
//       width: 100%;
//       display: flex;
//       align-items: center;
//       height: 100px;

//       // gap: 16px;
//       // input {
//       //   height: 100px;
//       //   width: 100%;
//       //   border: none;
//       //   outline: none;
//       //   box-shadow: var(--box-shadow);
//       // }
//     }
//   }
// }

// @media (max-width: 767px) {
//   .chat-modal {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     .container {
//       width: 100%;
//       height: 100%;
//       overflow: auto;
//       background-color: white;
//       border-radius: 0px;
//       display: flex;
//       flex-direction: column;
//       justify-content: flex-start;
//       gap: 40px;
//       .chat-header {
//         width: 100%;
//         display: flex;
//         align-items: center;
//         gap: 16px;
//         justify-content: space-between;
//         .wrapper {
//           display: flex;
//           align-items: center;
//           gap: 16px;
//           img {
//             width: 40px;
//             height: 40px;
//           }
//         }
//         .close {
//           display: flex;
//           align-items: center;
//           cursor: pointer;
//         }
//       }
//       .chats-container {
//         display: flex;
//         flex-direction: column;
//         gap: 24px;
//         .chat-body {
//           display: flex;
//           flex-direction: column;
//           p {
//             padding: 16px;
//             background-color: #ff73002e;
//             width: 90%;
//             border-radius: 20px 0px 20px 20px;
//             font-size: 13px;
//             font-weight: 300;
//             line-height: 18.85px;
//             min-height: 55px;
//           }
//         }
//         .chat-body-replay {
//           direction: ltr;
//           display: flex;
//           flex-direction: column;
//           p {
//             padding: 16px;
//             background: var(--btn-background);
//             width: 90%;
//             border-radius: 0px 20px 20px 20px;
//             color: white;
//             font-size: 13px;
//             font-weight: 300;
//             line-height: 18.85px;
//             min-height: 55px;
//           }
//         }
//       }
//     }
//   }
// }

// @media only screen and (min-width: 768px) and (max-width: 1024px) {
// }
