.student-points {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 16px;
  .container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 32px;
    .items-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 24px;
      .item {
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
          flex: 0.5;
          &:first-child {
            flex: 1;
          }
        }
        img {
          cursor: pointer;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .student-points {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;
      .items-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 32px;
        .item {
          width: 100%;
          display: flex;
          align-items: center;

          span {
            font-size: 12px;
            flex: none;
          }
          img {
            cursor: pointer;
            width: 24px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .student-points {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 16px;
    .container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;
      .items-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 32px;
        .item {
          width: 70%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 32px;
          span {
            flex: none;
            font-size: 14px;
            font-weight: 600;
          }
          img {
            cursor: pointer;
          }
        }
      }
    }
  }
}

.message-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  .container {
    width: 400px;
    height: auto;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .header {
      align-self: flex-end;
      cursor: pointer;
    }
    .body {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      gap: 10px;
      h6 {
        color: var(--main-color);
      }
    }
    .footer {
      width: 100%;
      .action-btn {
        width: 100%;
        outline: none;
        border: none;
        padding: 10px;
        font-weight: 600;
        color: white;
        border-radius: 5px;
        background: var(--btn-background);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
      }
    }
  }
}

@media (max-width: 767px) {
  .message-modal {
    .container {
      width: 90%;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
}
