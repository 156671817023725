.work-areas {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
  .header {
    width: 100%;
    display: flex;
    justify-content: flex-end !important;
  }

  .area {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    box-shadow: var(--box-shadow);
    border-radius: 10px;
    padding: 16px;
    .text-container {
      display: flex;
      flex-direction: column;
      gap: 8px;
      span {
        font-size: 17px;
        font-weight: 700;
        line-height: 25.5px;

        &:first-child {
          color: #ff7300;
        }
        &:last-child {
          color: #a4acad;
        }
      }
    }
    .actions-container {
      display: flex;
      align-items: center;
      gap: 8px;
      div {
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }
  }
}

.regions-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  .container {
    width: 400px;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    gap: 24px;
    border-radius: 10px;
    form {
      .control-group {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 16px;
        .select {
          width: 100%;
        }
        .form-input {
          width: 100% !important;
        }

        .form-actions {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 16px;
        }
      }
    }
  }
}
