.most-students {
  height: 100%;
  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .filter-container {
        display: flex;
        gap: 24px;
        align-items: center;
        img {
          cursor: pointer;
          width: 54px;
          height: 54px;
        }
        .date {
          padding: 10px 20px;
          border-radius: 10px;
          border: 1px solid var(--main-color);
        }
        .cancel {
          color: var(--main-color);
          cursor: pointer;
        }
      }
    }
  }
}

.filter-modal {
  .container {
    width: 500px;
    height: 300px;
    .header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .filter-container {
        display: flex;
        gap: 16px;
        align-items: center;
      }
      img {
        width: 40px;
        height: 40px;
        cursor: pointer;
      }
    }
  }
}
